/**
 * 
 * Machine not already configured 
 */
export const environment = {
  production: true,
  environmentName: 'prd',
  context: '',
  apiUrl: 'https://api.dronematch.fr'
};
